<template>
        <div class="d-flex">
            <slot></slot>

            <div class="form-check-inline ms-auto" v-if="!props.insertOnly">
                <div class="form-check form-check-inline" :title="$t('Insert, keep existing')">
                    <input class="form-check-input" name="updateOptions" type="radio" id="insert" value="Insert" v-model="updateOptions">
                    <label class="form-check-label" for="insert">{{$t('Insert')}}</label>
                </div>
                <div class="form-check form-check-inline" :title="$t('Insert and replace existing')">
                    <input class="form-check-input" name="updateOptions" type="radio" id="overwrite" value="Overwrite" v-model="updateOptions">
                    <label class="form-check-label" for="overwrite">{{$t('Overwrite')}}</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" name="updateOptions" type="radio" id="remove" value="Remove" v-model="updateOptions">
                    <label class="form-check-label" for="remove">{{$t('Remove')}}</label>
                </div>
                <small v-if="updateOptions == 'Remove'">
                    {{$t('Replace with')+':'}}
                    <OOrgunitsLookup :bind="sel => { replaceName = sel.Name; replace_ID = sel.ID }" blockedContent="atbl_Arena_Documents" v-if="configType == 'OrgUnit'">
                        <template #orgunit>
                            <a href="#">
                                <span v-if="!replace_ID">{{$t('None')}}</span>
                                <span v-else>{{ replaceName }}</span>
                            </a>
                        </template>
                    </OOrgunitsLookup>
                </small>
            </div>
        </div>
        <ODataGrid 
            :data-object="dsBatchSetup"
            hideGridMenu
            hideMultiselectColumn 
            newRecordsPosition="bottom"
            style="min-height:300px;"
            ref="batchSetupRef"
        >

            <OColumn colId="OrgUnit" width="400" editable :headerName="$t('Org Unit')"  v-if="props.configType == 'OrgUnit'">
                <template #default="{row}">
                    {{ row.JsonField.OrgUnit }}
                </template>
                <template #editor="{row}">
                    <OOrgunitsLookup :bind="sel=>{row.General_ID  = sel.ID; row.JsonField.OrgUnit = sel.OrgUnit; row.JsonField.UnitType = sel.UnitType;}">
                        <template #orgunit>
                            <input type="text" :value="row.JsonField.OrgUnit">
                        </template>
                    </OOrgunitsLookup> 
                </template>

            </OColumn>
            <OColumn colId="UnitType" width="200" editable :headerName="$t('Unit Type')" v-if="props.configType == 'OrgUnit'">
                <template #default="{row}">
                    {{ row.JsonField.UnitType }}
                </template>
            </OColumn>

            <OColumn colId="DoNotInherit" width="200" editable :headerName="$t('Do Not Inherit')" v-if="props.configType == 'OrgUnit' && !props.doNotShowDoNotInherit">
                <template #default="{row}">
                    <OBitEditor v-model="row.JsonField.DoNotInherit"/>
                </template>
            </OColumn>
        </ODataGrid>
</template>

<script setup>
    import { ref, onMounted, watch } from 'vue';
    import { getOrCreateProcedure } from 'o365-modules'
    import { OOrgUnitsLookup as OOrgunitsLookup } from 'o365-system-lookups';

    import { getOrCreateDataObject } from 'o365-dataobject'

    const props = defineProps({
        configType: {
            type: String, 
            default: 'OrgUnit' // could be based on some hardcoded view to be used in applyProc+fetching configType
        },
        viewName: {
            type: String, 
            required: true
        },
        insertOnly: {
            type: Boolean, 
            default: false
        },
        doNotShowDoNotInherit: {
            type: Boolean, 
            default: false
        }
    })

    const updateOptions = ref("Insert");
    const replace_ID = ref(null);
    const replaceName = ref(null);

    const dsBatchSetup = getOrCreateDataObject({
        id: 'dsBatchSetup',
        viewName: 'atbv_Arena_BatchConfig',
        maxRecords: 50,
        allowInsert:true,
        allowUpdate:true,
        allowDelete:true,
        fields: [
            { name: "PrimKey", type: "string" },
            { name: "General_ID", type: "number" },
            { name: "ConfigJson", type: "string" },
            { name: "ViewName", type: "string" }
        ]
    });

    dsBatchSetup.fields.ConfigJson.jsonAlias = "JsonField";

    dsBatchSetup.recordSource.whereClause = props.viewName ?  `ViewName = '${props.viewName}'` : '';
    dsBatchSetup.load().then();

    dsBatchSetup.on('BeforeCreate', (options) => {
        if (props.viewName){
            options.values["ViewName"] = props.viewName;
        }
    });

    const getOptions = ()=>{
        return {Operation: updateOptions.value, Replace_ID: replace_ID.value}
    }
    const refresh = async ()=>{
        updateOptions.value = "Insert";
        Replace_ID.value = null;
        await dsBatchSetup.load();
    }

    defineExpose({
        getOptions,
        refresh
    })

</script>